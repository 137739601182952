<template>
  <div class="flex justify-center">
    <div
      class="flex-grow bg-white shadow py-12 md:px-24 mt-12 max-w-2xl rounded"
    >
      <font-awesome-icon
        :icon="['far', 'spinner']"
        class="text-gray-300 fa-spin text-4xl"
      />
      <h2 class="mt-4">Loading Payment Page</h2>
      <h2 class="mt-4 text-red-500" v-if="error">We're having issues loading the payment screen. Try reloading the page.</h2>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51I5bGsEFjYzrKjvLbAI0iwY7GaulzPPaW3zR5JTbB7VrpZfCUup0Zte10kH3DNiz3Aydve2lHmwKaoaxEF6KrQnP00DArui8If"
);

export default {
  name: "LookupPayment",
  data() {
      return {
          error: false
      }
  },
  async mounted() {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("id")) {
        const stripe = await stripePromise;

        let postBody = {
          amount:  parseInt(query.get("amount")),
          id: query.get("id"),
        };

        const response = await fetch("/api/checkout/pay", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(postBody),
        });

        const session = await response.json();
        
        if (session.error) {
            this.error = true
            console.error(session.error);
            return
        }

        // redirect to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.error(result.error);
          this.error = true
          return
        }
    }
  },
};
</script>
